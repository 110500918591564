<template>
  <v-container fluid class="fill-height">
    <v-row>
      <v-col cols="12" xs="12" sm="8" md="8" lg="7" class="ma-auto">
        <v-card class="px-4 pt-6 pb-12" outlined>
          <v-row class="justify-space-between">
            <v-col cols="12" sm="12" md="6">
              <v-card-title class="text-center">
                
                <router-link to="/"
          >
          <!-- TODO : make this component -->
  <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
 width="70.000000pt" height="100%" viewBox="250 0 500.000000 326.000000"
 preserveAspectRatio="xMidYMid meet">

<g transform="translate(0.000000,326.000000) scale(0.100000,-0.100000)"
fill="#5c2e91" stroke="none">
<path fill="#5c2e91" d="M3290 2260 l0 -170 25 0 25 0 0 170 0 170 -25 0 -25 0 0 -170z"/>
<path fill="#5c2e91" d="M3400 2260 c0 -94 3 -169 8 -167 120 66 282 161 282 166 0 4 -42 31
-92 59 -51 28 -117 65 -145 82 l-53 30 0 -170z"/>
<path fill="#5c2e91" d="M3257 2045 c-64 -16 -107 -51 -152 -123 -1 -1 -21 16 -44 38 -113
107 -292 82 -376 -54 -76 -124 -10 -302 131 -351 32 -11 38 -17 35 -37 -1 -13
-4 -57 -5 -99 -1 -68 2 -80 29 -125 40 -63 107 -106 179 -112 l54 -4 -15 -45
c-40 -117 26 -258 142 -303 44 -17 134 -15 180 4 75 32 134 107 149 188 6 33
-3 98 -20 141 -5 14 0 17 26 17 156 0 273 162 226 313 -13 44 -13 49 2 53 129
41 191 119 192 242 0 126 -93 225 -224 239 -64 7 -137 -22 -186 -72 l-33 -34
-40 45 c-21 24 -53 52 -71 61 -42 23 -127 31 -179 18z"/>
<path :fill="$vuetify.theme.dark ? '#FFFFFF': '#000000' " d="M4483 1892 c-103 -37 -167 -96 -214 -197 -84 -179 -47 -402 86 -523
71 -65 145 -94 245 -96 93 -1 141 15 196 66 l43 39 6 -45 7 -46 59 0 59 0 0
220 0 220 -170 0 -170 0 0 -70 0 -70 90 0 90 0 -6 -27 c-34 -146 -222 -191
-329 -79 -115 119 -89 388 44 456 48 24 138 27 179 6 35 -18 68 -54 81 -88 10
-27 12 -28 95 -28 l84 0 -10 43 c-20 88 -76 157 -168 203 -73 37 -216 45 -297
16z"/>
<path :fill="$vuetify.theme.dark ? '#FFFFFF': '#000000' " d="M5341 1670 c-24 -11 -59 -37 -78 -58 -19 -20 -34 -32 -33 -27 2 6 2
27 1 48 l-1 37 -75 0 -75 0 0 -290 0 -290 79 0 79 0 4 168 c3 146 6 172 25
207 27 52 62 70 135 70 l59 0 -3 75 -3 75 -35 3 c-19 1 -55 -7 -79 -18z"/>
<path :fill="$vuetify.theme.dark ? '#FFFFFF': '#000000' " d="M5690 1684 c-80 -15 -145 -60 -171 -118 -33 -72 -30 -76 61 -76 66 0
80 3 80 16 0 8 13 29 29 45 25 24 37 29 81 29 41 0 56 -5 75 -25 14 -13 25
-33 25 -45 0 -28 -33 -58 -72 -64 -18 -3 -72 -12 -120 -20 -52 -8 -103 -23
-124 -37 -103 -63 -93 -231 16 -286 75 -38 202 -30 272 19 34 24 38 23 38 -7
0 -25 1 -25 86 -25 66 0 83 3 77 13 -4 6 -10 116 -13 244 -6 265 -7 268 -93
312 -37 18 -67 24 -135 27 -48 1 -98 0 -112 -2z m180 -346 c0 -49 -18 -103
-39 -122 -27 -24 -84 -36 -127 -26 -45 10 -52 17 -60 56 -10 52 25 80 126 103
47 10 88 19 93 20 4 0 7 -14 7 -31z"/>
<path :fill="$vuetify.theme.dark ? '#FFFFFF': '#000000' " d="M6396 1680 c-16 -5 -46 -23 -68 -40 l-38 -31 0 31 0 30 -75 0 -75 0
0 -390 0 -390 80 0 80 0 0 135 0 134 25 -25 c88 -88 271 -66 344 40 80 116 79
314 -3 421 -56 73 -182 113 -270 85z m94 -128 c63 -35 94 -141 70 -241 -23
-97 -117 -140 -198 -91 -45 28 -65 79 -65 166 1 86 26 143 75 167 43 22 76 21
118 -1z"/>
<path :fill="$vuetify.theme.dark ? '#FFFFFF': '#000000' " d="M7003 1681 c-49 -13 -119 -57 -152 -98 -98 -119 -81 -341 32 -438 59
-50 122 -68 223 -63 106 6 163 37 212 118 17 30 32 58 32 62 0 4 -31 8 -70 8
-57 0 -71 -3 -79 -19 -23 -43 -134 -66 -184 -39 -41 22 -67 64 -67 109 0 18 9
19 206 19 l207 0 -5 73 c-7 112 -63 203 -153 249 -44 23 -148 33 -202 19z
m150 -139 c21 -18 47 -64 47 -82 0 -6 -44 -10 -125 -10 -115 0 -125 1 -125 19
0 26 38 71 74 87 38 17 103 10 129 -14z"/>
</g>
</svg>
        </router-link>

              </v-card-title>
              <v-card-subtitle class="mb-5"
                >그레이프 계정을 생성하세요</v-card-subtitle
              >
              <v-card-text>
                <ValidationObserver ref="form" v-slot="{ handleSubmit, reset }">
                  <form
                    @submit.prevent="handleSubmit(signin)"
                    @reset.prevent="reset"
                  >
                    <ValidationProvider
                      v-slot="{ errors }"
                      name="Email"
                    >
                      <v-text-field
                        v-model="email"
                        :error-messages="errors"
                        label="이메일"
                        class="mb-3"
                        outlined
                        dense
                      ></v-text-field>
                    </ValidationProvider>
                    <ValidationProvider
                      v-slot="{ errors }"
                      name="채널 이름"
                    >
                      <v-text-field
                        v-model="channelName"
                        :error-messages="errors"
                        label="채널 이름"
                        outlined
                        dense
                      ></v-text-field>
                    </ValidationProvider>
                    <v-row>
                      <v-col cols="6">
                        <ValidationProvider
                          v-slot="{ errors }"
                          name="Password"
                        >
                          <v-text-field
                            v-model="password"
                            type="password"
                            :error-messages="errors"
                            label="비밀번호"
                            outlined
                            dense
                          ></v-text-field>
                        </ValidationProvider>
                      </v-col>
                      <v-col cols="6">
                        <ValidationProvider
                          v-slot="{ errors }"
                          name="비밀번호 확인"
                        >
                          <v-text-field
                            type="password"
                            v-model="confirmPassword"
                            :error-messages="errors"
                            label="비밀번호 확인"
                            outlined
                            dense
                          ></v-text-field>
                        </ValidationProvider>
                      </v-col>
                    </v-row>
                    <div class="mt-6 d-flex justify-space-between">
                      <v-btn
                        text
                        small
                        class="pl-0 text-capitalize"
                        color="primary"
                        router
                        to="signin"
                        >로그인 하기</v-btn
                      >
                      <v-btn type="submit" class="primary" depressed
                        >회원가입</v-btn
                      >
                    </div>
                  </form>
                </ValidationObserver>
              </v-card-text>
            </v-col>
            <v-col cols="5" class="align-self-center hidden-sm-and-down">
              <v-responsive>
                <svg
                  id="b59c9f89-c93e-413f-a14b-776784e615e2"
                  data-name="Layer 1"
                  xmlns="http://www.w3.org/2000/svg"
                  style="max-width: 100%"
                  viewBox="0 0 1075.51587 646.89913"
                >
                  <title>Create Account</title>
                  <path
                    d="M136.59282,733.55706l63.43134-91.13824L136.56059,743.4707l-.037,10.3692q-6.78516-.05706-13.43322-.44141c-.02019-2.35442,5.37117-131.89917,5.142-134.24281.3129-.0888.48846-12.252.6126-12.78462L62.57779,506.77732,128.91613,596.945l.1978,2.66552,3.93549-100.64793L76.0857,395.895,133.5,481.27779c.02132-1.3695,2.71745-208.02265,2.744-209.29776.01628.67732,1.25371,164.45521,1.26281,165.14538l54.47905-65.92288-54.53131,80.08836-.27852,90.07922,50.556-87.10756C124.63867,581.2954,138.60155,536.8418,136.99011,604.702l73.43568-121.29149L136.92724,622.17566Z"
                    transform="translate(-62.24207 -126.55043)"
                    fill="#d0cde1"
                  />
                  <path
                    d="M1106.98992,745.9302l26.4334-37.97954-26.44683,42.1108-.0154,4.3211q-2.82754-.02378-5.598-.18395c-.00841-.98114,2.2383-54.96562,2.14282-55.94228.13039-.037.20355-5.10569.25528-5.32766L1076.146,651.42551l27.64482,37.57509.08242,1.11079,1.64-41.94247-23.73818-42.95081,23.92592,35.58112c.00889-.57071,1.13243-86.68815,1.14349-87.21952.00678.28226.52245,68.53253.52625,68.82014l22.70275-27.47169L1107.349,628.303l-.11607,37.53823,21.06792-36.29986c-26.29252,52.9377-20.47384,34.4128-21.14537,62.69183l30.60245-50.54515-30.62865,57.82684Z"
                    transform="translate(-62.24207 -126.55043)"
                    fill="#d0cde1"
                  />
                  <ellipse
                    cx="536"
                    cy="625.89913"
                    rx="536"
                    ry="21"
                    fill="#d0cde1"
                  />
                  <rect
                    x="184"
                    y="248.03055"
                    width="612"
                    height="364.87"
                    fill="#3f3d56"
                  />
                  <path
                    d="M397.70209,374.581a146.35424,146.35424,0,0,1-146.32,143.97c-1.72,0-3.43-.03-5.14-.09V374.581Z"
                    transform="translate(-62.24207 -126.55043)"
                    fill="#2b81d6"
                  />
                  <path
                    d="M582.17908,739.45064H523.9723a31.00847,31.00847,0,0,1,58.20678,0Z"
                    transform="translate(-62.24207 -126.55043)"
                    fill="#2b81d6"
                  />
                  <path
                    d="M858.24207,550.72094v122.9a61.52336,61.52336,0,0,1,0-122.9Z"
                    transform="translate(-62.24207 -126.55043)"
                    fill="#2b81d6"
                  />
                  <rect
                    x="279.45927"
                    y="380.6149"
                    width="422.14211"
                    height="36.06239"
                    rx="18.03118"
                    fill="#fff"
                  />
                  <rect
                    x="279.45927"
                    y="451.67903"
                    width="422.14211"
                    height="36.06239"
                    rx="18.03118"
                    fill="#fff"
                  />
                  <rect
                    x="553.59711"
                    y="522.74315"
                    width="148.00427"
                    height="36.06239"
                    rx="18.03118"
                    fill="#fff"
                  />
                  <path
                    d="M955.29387,238.67007s16.82875,6.54451,17.76368,28.04791-4.67465,81.33893-4.67465,81.33893,20.56846,29.91777,4.67465,38.33214-17.76368-33.65749-17.76368-33.65749l-18.6986-88.81837S947.81443,237.73514,955.29387,238.67007Z"
                    transform="translate(-62.24207 -126.55043)"
                    fill="#a0616a"
                  />
                  <path
                    d="M783.26671,307.85491s-40.202,26.178-42.07187,5.60958S753.34894,212.492,753.34894,212.492s1.86986-34.59242,21.50339-26.178-5.60958,37.39721-5.60958,37.39721l2.80479,65.44511,18.69861-.93493Z"
                    transform="translate(-62.24207 -126.55043)"
                    fill="#a0616a"
                  />
                  <path
                    d="M847.77689,332.1631s-123.41079-45.81159-116.86628,7.47944,38.33214,120.606,38.33214,120.606l11.21917,17.76368h23.37325L788.87629,381.7144s53.291,29.91777,69.18484,25.24312c0,0,20.56846,137.43475,28.04791,147.719s20.56846,134.63,20.56846,134.63l12.15409,14.95888h23.37326V493.906s40.202-130.89023,9.3493-137.43475S847.77689,332.1631,847.77689,332.1631Z"
                    transform="translate(-62.24207 -126.55043)"
                    fill="#2f2e41"
                  />
                  <path
                    d="M777.65712,465.85812s-5.60958-10.28423-14.95888,0-31.78763,22.43833-31.78763,22.43833-48.61637,11.21916-9.3493,20.56847,43.94172,0,43.94172,0,6.54451,1.86986,12.15409,3.73972S819.729,507.93,819.729,502.3204s-12.37851-39.134-17.40842-34.05842S777.65712,465.85812,777.65712,465.85812Z"
                    transform="translate(-62.24207 -126.55043)"
                    fill="#2f2e41"
                  />
                  <path
                    d="M920.6231,695.01108s-13.7851-11.35244-17.02865-8.10888-20.2722,25.13753-20.2722,25.13753-45.40973,34.86818-4.05444,40.5444,38.92263-9.73066,38.92263-9.73066h12.9742c1.62178,0,23.51576-9.73066,22.70487-13.7851s-10.54155-37.11568-13.7851-34.7756S920.6231,695.01108,920.6231,695.01108Z"
                    transform="translate(-62.24207 -126.55043)"
                    fill="#2f2e41"
                  />
                  <circle
                    cx="733.17873"
                    cy="44.80466"
                    r="31.78763"
                    fill="#a0616a"
                  />
                  <path
                    d="M817.85913,167.61537s24.30818,20.56847,41.13693,20.56847S834.68787,226.516,834.68787,226.516l-36.46228-32.72256Z"
                    transform="translate(-62.24207 -126.55043)"
                    fill="#a0616a"
                  />
                  <path
                    d="M827.20843,226.516l-.728-7.36568s18.4917-32.83632,24.10129-35.64112,9.3493-2.80479,9.3493-2.80479,18.6986-19.63353,39.26707-9.3493,69.18484,75.72935,69.18484,75.72935-34.59242,2.80479-29.91777,29.91777c0,0,34.59242,88.81838,25.24312,89.75331s-37.39721-5.60959-66.38,7.47944-42.07186,4.67465-42.07186,4.67465l-14.95889-48.61637s-28.04791-15.89382-27.113-29.91777L772.515,318.60661l3.73972-34.59242,24.77565-28.51537S796.35573,251.75909,827.20843,226.516Z"
                    transform="translate(-62.24207 -126.55043)"
                    fill="#575a89"
                  />
                  <path
                    d="M766.18031,174.79171a11.73657,11.73657,0,0,1-4.616,3.14369c-1.81051.51062-4.09154-.11555-4.81307-1.85281l1.34411-1.74939-4.02944-2.5039,1.75581-2.06012-3.24556-3.5243,2.56163-2.03236-4.87719-3.48808c6.32111-10.31466,13.15729-20.75845,23.13705-27.59607s23.9246-9.26726,34.14813-2.7998c5.30792,3.35782,9.06128,8.68723,12.06785,14.20172,6.04656,11.09024,11.37634,21.9007,12.16329,34.50764-3.79816-3.69869-9.34319-5.783-13.14136-9.48173a28.463,28.463,0,0,0-5.66925-4.64065c-3.97269-2.24026-8.726-2.43845-13.17348-3.449-3.73952-.84974-6.16609-2.53534-8.99529-4.94505-3.36564-2.8666-3.50275-1.7582-6.90135,1.46926Q775.04391,166.39718,766.18031,174.79171Z"
                    transform="translate(-62.24207 -126.55043)"
                    fill="#2f2e41"
                  />
                </svg>
              </v-responsive>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'SignUp',
  data: () => ({
    email: '',
    channelName: '',
    password: '',
    confirmPassword: ''
  }),
  methods: {
    signin() {
      console.log('hello')
    }
  }
}
</script>

<style></style>
